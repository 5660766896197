import React from "react"
import Helmet from 'react-helmet'
import Layout from "../components/layout"
import PageHeader from '../components/page-header';

export default class myFiles extends React.Component {

    render(){
        return (
          <Layout>
            <Helmet title='404' />
            <PageHeader pageTitle={'Désolé ! Il n\'y a rien ici.'} />
          </Layout>
        )
    }
}

